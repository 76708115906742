/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { element } from "prop-types"

// You can delete this file if you're not using it

export const onClientEntry = () => {
  window.onload = () => {
    /* do stuff */
    window.addEventListener("scroll", function () {
      var value = window.scrollY
      if (document.querySelector(".header--fixed")) {
        if (value > 100) {
          document.querySelector(".header--fixed").classList.add("sticky")
        } else {
          document.querySelector(".header--fixed").classList.remove("sticky")
        }
      }
    })

    var elements = document.querySelectorAll(".has-droupdown > a")
    if(elements && element.length > 0){
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
            elements[i].onclick = function () {
                this.parentElement
                .querySelector(".submenu")
                .classList.toggle("active")
                this.classList.toggle("open")
            }
            }
        }
    }
  }
}
