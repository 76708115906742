// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-elements-blog-details-jsx": () => import("./../src/elements/BlogDetails.jsx" /* webpackChunkName: "component---src-elements-blog-details-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-executive-coaching-js": () => import("./../src/pages/executive-coaching.js" /* webpackChunkName: "component---src-pages-executive-coaching-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadership-development-js": () => import("./../src/pages/leadership-development.js" /* webpackChunkName: "component---src-pages-leadership-development-js" */),
  "component---src-pages-who-is-john-mercer-js": () => import("./../src/pages/who-is-john-mercer.js" /* webpackChunkName: "component---src-pages-who-is-john-mercer-js" */),
  "component---src-templates-journal-list-js": () => import("./../src/templates/journal-list.js" /* webpackChunkName: "component---src-templates-journal-list-js" */)
}

